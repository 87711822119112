/*eslint-disable*/
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Box,
	Container,
	Divider,
	Typography,
	experimentalStyled,
} from '@material-ui/core';
import gtm from '../../lib/gtm';

import { useTranslation } from 'react-i18next';
import '../../styles/landing.css';
import { ChatThread } from '../../components/dashboard/chat';
import { AppDispatch, useSelector } from 'src/store';
import MainNav from 'src/components/navigation/MainNav';
import ChatLayout from './ChatLayout';
import { getThreads, getChatAccuracySettings, getChatQuestions } from 'src/slices/chat';
import { useDispatch } from 'react-redux';
// import SelectModel from 'src/components/dashboard/SelectModel';

const PageWrapper = experimentalStyled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	width: '100%'
}));

const BodyContainer = experimentalStyled(Container)``;

const HeaderWrapper = experimentalStyled(Box)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	'& .divider': {
		borderColor: theme.palette.common.white
	},
	'& .MuiIconButton-root': {
		height: 'fit-content',
		padding: 0
	}
}));

const ContentContainer = experimentalStyled(Container)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
}));

const QuestionBox = experimentalStyled(Box)(({ theme }) => ({
	backgroundColor: 'transparent',
	color: theme.palette.primary.dark,
	':hover': {
		backgroundColor: theme.palette.action.hover,
		color: theme.palette.primary.contrastText,
	}
}));

const LandingPage: FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { isAuthenticated = false } = useSelector((state) => state.auth);

	const { activeThreadId, showSideBar } = useSelector((state) => state.chat);

	useEffect(() => {
		gtm.push({ event: 'page_view' });
		dispatch(getThreads());
		dispatch(getChatAccuracySettings());
		dispatch(getChatQuestions());
	  }, []);
	
	  useEffect(() => {
		if (activeThreadId && activeThreadId != '') {
		  navigate(`/home/${activeThreadId}`);
		}
	  }, [activeThreadId]);

	return (
		<>
			<Helmet>
				<title>{t("askDEWA")}</title>
			</Helmet>
			<ChatLayout>

				{/* <PageWrapper> */}

				{/* <BodyContainer className='body-container' maxWidth={false}>
						<HeaderWrapper className='header-wrapper'>
							<div className='logo-wrapper'>
								<RouterLink
									to=""
									onClick={(ev: any) => ev.preventDefault()}
								>
									<img alt="Attachment" src={DEWALOGO} />
								</RouterLink>
								<Divider className='divider' orientation='vertical' variant='middle' />
								<Typography
									component="p"
									className='title'
									color="primary.contrastText"
								>
									{t("askDEWA")}
								</Typography>
							</div>
							{isAuthenticated && <MainNav />}
						</HeaderWrapper> */}
				<ContentContainer className='content-container' maxWidth={false}>
					<ChatThread />
				</ContentContainer>
				{/* </BodyContainer>
			</PageWrapper > */}
			</ChatLayout >
		</>
	);
};

export default LandingPage;
