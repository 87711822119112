/*eslint-disable*/
import React, { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { Lightbox } from 'react-modal-image';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Stack,
  Link,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector } from 'src/store';
import { useDispatch } from 'react-redux';
import { markMessageAsSeen, toggleIsDocumentViewerOpen } from 'src/slices/chat';
import useSettings from 'src/hooks/useSettings';
// import { OPENAI_ID } from '../SelectModel';
import OpenAIIcon from 'src/icons/OpenAIIcon.svg';
import { ChatMessageWidth } from 'src/constants';
import ChatBotIcon from 'src/icons/chat-bot.png';
import ChatLikeIcon from 'src/icons/chat-like-btn.svg';
import ChatDislikeIcon from 'src/icons/chat-dislike-btn.svg';
import ChatToolButton from './ChatToolButton';
import ReactMarkdown from 'react-markdown';
import { CopyIconButton } from 'src/components/CopyIconButton/index';
import TypingMarkdown from './TypingMarkdown';
import { ExpandMore, InfoOutlined, Refresh, AllInclusiveOutlined, TranslateOutlined, ArrowDropDownSharp } from '@material-ui/icons';
import DocumentText from 'src/icons/DocumentText';
import colors from 'src/theme/colors';
import { extractFileName } from 'src/utils';
import { DEPARTMENTS, SECURITY_CLEARANCE } from 'src/components/navigation/MainNav';
import ThumbsUpDownDialog from './dialogs/ThumbsUpDownDialog';
import ScoreTypeView from './dialogs/ScoreTypeView';
import { getDocumentDownloadLink, getScore, fetchChatTranslate } from 'src/api/chat';
import { GetDocumentPayload, GetScoreTypePayload } from 'src/types/chat';
import RTL from '../../RTL'
interface ChatMessageProps {
  body: any;
  loader: any;
  contentType?: string;
  createdAt?: number;
  senderAvatar?: string;
  senderName?: string;
  senderType?: 'dewa' | 'contact' | 'user';
  threadKey?: any;
  thread?: any;
  index?: any;
  showIcons: boolean;
  msgId?: string;
  externalConvId?: string;
  msgLoading: boolean;
  reaction?: any;
  source_documents?: any;
  loadAtOnce?: boolean;
  onPdfDocClick?: (url: string, source_documents: any) => void;
}

const ChatMessage: FC<ChatMessageProps> = (props) => {
  const {
    loader,
    body,
    contentType,
    senderAvatar,
    senderType,
    threadKey,
    thread,
    index,
    showIcons,
    msgId,
    externalConvId,
    msgLoading,
    reaction,
    source_documents = {},
    loadAtOnce = false,
    onPdfDocClick,
    ...other
  } = props;
  const dispatch = useDispatch();
  const [expandMedia, setExpandMedia] = useState<boolean>(false);
  const { activeThreadId, isShowAccuracy } = useSelector((state) => state.chat);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showTranslation, setShowTranslation] = useState<boolean>(false);
  // const { selectedModel } = thread;
  const { byId = {} } = useSelector((state) => state.metadata);
  const hasSourceDocuments = Object.keys(source_documents ?? {}).length > 0;
  // const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
  const { settings } = useSettings();
  const [showScoreType, setShowScoreType] = useState<boolean>(false);
  const [answerRelevance, setAnswerRelevance] = useState<any>(0);
  const [contextRelevance, setContextRelevance] = useState<any>(0);
  const [groundedness, setGroundedness] = useState<any>(0);
  const [chatTranslation, setChatTranslation] = useState<any>({ question: '', answer: '' });


  const [thumbsUpDownDialogOpen, setThumbsUpDownDialogOpen] = useState({
    open: false,
    btnClicked: '',
    msgId: null,
  });


  const selectAvatarSrc = () => {
    // let avatarIcon;
    // if (body.icon === 'DEFAULT' || body === 'showLoader') {
    //   if (selectedModel == OPENAI_ID) {
    //     avatarIcon = OpenAIIcon; // OPENAI
    //   } else {
    //     avatarIcon = ChatBotIcon;
    //   }
    // } else {
    //   avatarIcon = body.icon;
    // }
    return ChatBotIcon;
  };

  function moment(createdAt: number) {
    throw new Error('Function not implemented.');
  }

  const getScoreByType = async (external_conversation_id, score_type) => {
    const payload = {
      model_type: "Bedrock",
      model_name: "anthropic.claude-v2:1",
      external_conversation_id: external_conversation_id,
      score_type: score_type,
    };

    return getScore(payload)
      .then((resp) => resp?.data)
      .catch((err) => {
        console.log(err);
        throw err; // rethrow
      });
  };

  const handleScoreTypeButtonClick = async (event, external_conversation_id) => {
    event.preventDefault();
    const isFetchScore = !showScoreType;
    setShowScoreType(isFetchScore);

    if (isFetchScore) {
      // Array of score types
      const scoreTypes = ['answer_relevance', 'context_relevance', 'groundedness'];

      const responses = await Promise.all(
        scoreTypes.map(async (scoreType) => {
          try {
            const response = await getScoreByType(external_conversation_id, scoreType);
            return response;
          } catch (err) {
            console.error(`Error: ${scoreType} score:`, err);
            throw err;
          }
        })
      );

      const [answerRelevance, contextRelevance, groundedness] = responses;
      setAnswerRelevance(answerRelevance);
      setContextRelevance(contextRelevance);
      setGroundedness(groundedness);
    }

  };

  const handlePdfDocClick = (event, url, source_docs) => {
    // Handle the PDF document click event
    event.preventDefault();
    // const url = event.currentTarget.getAttribute('href');

    if (url) {
      const payload: GetDocumentPayload = {
        url: url
      }
      getDocumentDownloadLink(payload).then(resp => resp?.data).then(response => {
        onPdfDocClick(response.presigned_url, source_docs);
      })
        .catch(err => {
        });
    }

  };
  const handleTranslateButtonClick = async (event: any, external_conversation_id: string) => {
    const isFetch = !showTranslation;
    setShowTranslation(isFetch);
    if (isFetch) {
      const response = await fetchChatTranslate(external_conversation_id);
      if (response && response.data) {
        setChatTranslation(response.data);
      }
    }
  }
  const handleClose = () => {
    console.log("Handle Close");
    setShowScoreType(false);
  }

  return (
    <div style={{ direction: `${settings.direction}` }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 2,
        }}
        {...other}
      >
        <Box
          className="chatbot-wrapper"
          sx={{
            display: 'flex',
            maxWidth: ChatMessageWidth,
            width: 'calc(100% - 185px)',
            justifyContent: 'space-between',
            flexDirection: !smDown ? 'row' : 'column',
            px: 4,
            py: senderType === 'user'
              ? smDown
                ? 4
                : 0
              : 4,
            background:
              senderType === 'user'
                ? smDown
                  ? 'white'
                  : 'background.default'
                : '#ffffff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: !smDown ? 'center' : 'flex-start',
              width: !smDown ? '87%' : '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              {senderType === 'user' ? (
                <Avatar
                  variant="square"
                  className="user-avatar"
                  src={senderAvatar}
                  sx={{
                    height: 30,
                    width: 30,
                    borderRadius: '5px',
                  }}
                />
              ) : (
                <Avatar
                  variant="square"
                  sx={{
                    height: 30,
                    width: 30,
                    borderRadius: '5px',
                    backgroundColor: 'transparent',
                  }}
                >
                  <img
                    src={selectAvatarSrc()}
                    style={{ height: '30px', width: '30px' }}
                  />
                </Avatar>
              )}
            </Box>
            <Box
              sx={{
                marginLeft: '4px',
                marginTop: !smDown ? '0px' : '4px',
              }}
            >
              <Box
                sx={{
                  color:
                    settings.theme == 'DARK'
                      ? senderType === 'user'
                        ? '#3C4A59'
                        : 'white'
                      : '#3C4A59',
                  px: 1,
                }}
              >
                <Box sx={{ mt: 0, ml: 1 }}>
                  {contentType === 'image' ? (
                    <Box
                      onClick={(): void => setExpandMedia(true)}
                      sx={{
                        mt: 2,
                        '& img': {
                          cursor: 'pointer',
                          height: 'auto',
                          maxWidth: '100%',
                        },
                      }}
                    >
                      <img alt="Attachment" src={body} />
                    </Box>
                  ) : (
                    <Typography
                      color="inherit"
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: '0.875rem',
                          sm: '0.875rem',
                          md: '0.875rem',
                          lg: '1rem',
                          xl: '1.125rem'
                        },
                        color: '#3C3C3C',
                      }}
                    >
                      {senderType == 'dewa' &&
                        body !== 'showLoader' &&
                        !showIcons ? (
                        <TypingMarkdown
                          content={body}
                          speed={20}
                          threadId={thread?.external_chat_id}
                          showTyping={!loadAtOnce}
                          msgId={msgId}
                          onTypingComplete={async () => {
                            await dispatch(
                              markMessageAsSeen(thread?.external_chat_id, msgId, true)
                            );
                          }}
                        />
                      ) : senderType === 'dewa' && body === 'showLoader' ? (
                        loader
                      ) : (
                        <TypingMarkdown
                          content={body}
                          speed={20}
                          showTyping={false}
                          msgId={msgId}
                          threadId={''}
                          onTypingComplete={() => { }}
                        />
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {senderType == 'dewa' &&
            showIcons && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: !smDown ? 'space-between' : 'flex-end',
                  height: '100%',
                  alignItems: 'center',
                  ml: 0,
                }}
              >

                {/* <>
                <ChatToolButton
                  src={<CopyIconButton value={body} />}
                  onClick={() => { }}
                />
                {false && (
                  <ChatToolButton
                    sx={{ p: 0, mx: 1 }}
                    src={ChatLikeIcon}
                    onClick={() => { }}
                  />
                )}
                {false && (
                  <ChatToolButton src={ChatDislikeIcon} onClick={() => { }} />
                )}
              </> */}
              </Box>
            )}
          {senderType == 'dewa' &&
            showIcons && hasSourceDocuments && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: !smDown ? 'space-between' : 'flex-end',
                  height: '100%',
                  alignItems: 'center',
                  ml: 0,
                }}
              >
                <ChatToolButton
                  title='Copy'
                  sx={{ p: 0, mx: 0.5 }}
                  src={<CopyIconButton value={body} />}
                  onClick={() => { }}
                />

                {reaction == null || reaction.thumbs_up ? (
                  <ChatToolButton
                    title='Like'
                    disabled={reaction && reaction?.thumbs_up}
                    sx={{ p: 0, mx: 0.5, color: '#6b778c' }}
                    src={ChatLikeIcon}
                    onClick={() => {
                      reaction == null
                        ? setThumbsUpDownDialogOpen((prev) => {
                          return {
                            ...prev,
                            open: true,
                            btnClicked: 'like',
                            msgId: externalConvId,
                          };
                        })
                        : null;
                    }}
                  />
                ) : null}

                {reaction == null || reaction.thumbs_down ? (
                  <ChatToolButton
                    title='Dislike'
                    disabled={reaction && reaction?.thumbs_down}
                    sx={{ p: 0, mx: 0.5 }}
                    src={ChatDislikeIcon}
                    onClick={() => {
                      reaction == null
                        ? setThumbsUpDownDialogOpen((prev) => {
                          return {
                            ...prev,
                            open: true,
                            btnClicked: 'dislike',
                            msgId: externalConvId,
                          };
                        })
                        : null;
                    }}
                  />
                ) : null}
                <ChatToolButton
                  title='Refresh'
                  disabled={true}
                  sx={{ p: 0, mx: 0.5 }}
                  src={
                    <Refresh
                      sx={{
                        color: 'lightgray',
                        fill: 'lightgray',
                        transform: 'scaleX(-1)',
                        '&:hover': {
                          color: 'lightgray',
                          fill: 'lightgray',
                        },
                      }}
                    />
                  }
                  onClick={() => { }}
                />
                <ChatToolButton
                  title='Information Source'
                  src={
                    <InfoOutlined
                      sx={{
                        color: '#515151',
                        fill: '#515151',
                        '&:hover': {
                          color: '#515151',
                          fill: '#515151',
                        },
                      }}
                    />
                  }
                  sx={{ p: 0, mx: 0.5 }}
                  onClick={() => setShowDetails(!showDetails)}
                />
                <ChatToolButton
                  title='Translate'
                  src={
                    <TranslateOutlined
                      sx={{
                        color: '#515151',
                        fill: '#515151',
                        '&:hover': {
                          color: '#515151',
                          fill: '#515151',
                        },
                      }}
                    />
                  }
                  sx={{ p: 0, mx: 0.5 }}
                  onClick={(e: any) => handleTranslateButtonClick(e, externalConvId)}
                />

                {isShowAccuracy && (<ChatToolButton
                  title='Stats'
                  src={
                    <AllInclusiveOutlined
                      sx={{
                        color: '#515151',
                        fill: '#515151',
                        '&:hover': {
                          color: '#515151',
                          fill: '#515151',
                        },
                      }}
                    />
                  }
                  sx={{ p: 0, mx: 0.5 }}
                  onClick={(e: any) => handleScoreTypeButtonClick(e, externalConvId)}
                />)}
                {/* <ChatToolButton src={ChatDislikeIcon} onClick={() => { }} /> */}
                {/* <InfoOutlined onClick={ } sx={{ color: '#888', cursor: 'pointer' }} /> */}

                {/* <>
                <ChatToolButton
                  src={<CopyIconButton value={body} />}
                  onClick={() => { }}
                />
                {false && (
                  <ChatToolButton
                    sx={{ p: 0, mx: 1 }}
                    src={ChatLikeIcon}
                    onClick={() => { }}
                  />
                )}
                {false && (
                  <ChatToolButton src={ChatDislikeIcon} onClick={() => { }} />
                )}
              </> */}
              </Box>
            )}
        </Box>
      </Box>
      {
        senderType == 'dewa' &&
        showIcons && (
          <Box className={`axiata-container ${!showDetails || !hasSourceDocuments ? 'hide' : ''}`}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Accordion elevation={0} disableGutters sx={{
              maxWidth: ChatMessageWidth,
              width: '100%',
              px: 2,
            }}
            >
              <AccordionSummary sx={{ margin: 0, height: '2rem', minHeight: '2rem' }}>
                <Typography sx={{ fontSize: '.875rem', display: 'flex', alignItems: 'center' }}>Documents
                  <Chip sx={{
                    marginLeft: '0.25rem',
                    height: '1rem',
                    borderRadius: '0.25rem',
                    fontSize: '0.75rem',
                    'span': {
                      padding: '0.25rem',
                    }
                  }} variant='outlined' label="source_documents" />
                  <Chip sx={{
                    marginLeft: '0.25rem',
                    height: '1rem',
                    borderRadius: '0.25rem',
                    fontSize: '0.75rem',
                    'span': {
                      padding: '0.25rem',
                    }
                  }} variant='filled' label={Object.keys(source_documents).length} /></Typography>
              </AccordionSummary>
              <AccordionDetails>
                {source_documents && Object.keys(source_documents).map(sd => (
                  <Accordion disableGutters sx={{ border: theme => `1px solid ${theme.palette.divider}` }}>
                    <AccordionSummary sx={{
                      margin: 0,
                      height: '2rem',
                      minHeight: '2rem',
                      '.MuiAccordionSummary-content': {
                        display: 'flex', alignItems: 'center',
                      }
                    }} expandIcon={<ExpandMore />}>
                      <DocumentText sx={{ width: '1rem' }} />
                      <Typography sx={{ marginLeft: '0.5rem', fontSize: '.875rem', }}>{extractFileName(sd) ?? 'Document'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ overflow: 'auto' }}>
                      {source_documents[sd].map(doc => (
                        <>
                          <Typography sx={{ width: '55vw', marginTop: '0.625rem', marginBottom: '0.625rem', maxWidth: '800px', fontSize: theme => theme.typography.pxToRem(14) }}>
                            {doc?.page_content}
                          </Typography>
                          <Typography sx={{
                            background: colors.lightGreyFiftyPercent,
                            padding: '8px 5px',
                            borderRadius: '2px',
                            fontSize: theme => theme.typography.pxToRem(14),
                            display: 'inline'
                          }}>

                            <b>{doc.metadata?.is_bedrock ? 'Relevance Score:' : 'euclidean distance:'} </b>
                            {(parseFloat(doc.euclideanDistance) * 100).toFixed(2)}
                          </Typography>
                        </>
                      )
                      )}
                      <Box sx={{
                        borderTop: '1px solid #DCDCDC',
                        paddingTop: '0.5rem',
                        marginTop: theme => theme.typography.pxToRem(16),
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: theme => theme.typography.pxToRem(8),
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                      }}>
                        {/* <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>page: </b>
                          {'  '}
                          {'5'}
                        </Typography> */}

                        <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>source:</b>
                          {'  '}
                          <a href={sd} onClick={(ev) => handlePdfDocClick(ev, sd, source_documents[sd])} target='_blank'>{extractFileName(sd) ?? ''}</a>
                        </Typography>

                        <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>department: </b>
                          {byId[source_documents[sd]?.[0]?.metadata?.department_id]}
                        </Typography>
                        <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>security level: </b>
                          {SECURITY_CLEARANCE[source_documents[sd]?.[0]?.metadata?.security_level?.toLowerCase()]}
                        </Typography>
                      </Box>
                    </AccordionDetails >
                  </Accordion >
                ))}
              </AccordionDetails >
            </Accordion >
          </Box >
        )
      }
      {
        showScoreType && <ScoreTypeView
          answerRelevance={answerRelevance}
          contextRelevance={contextRelevance}
          groundedness={groundedness}
        />
      }

      {showTranslation && (

        <RTL name='DialogView' direction='ltr'>
          <Box sx={{ backgroundColor: '#f1f1f1', paddingBottom: '10px' }} className="tranalation-container">
            {chatTranslation.question === '' && <CircularProgress />}
            <Stack spacing={1} direction="row">
              <Typography variant="h6">
                Translation
              </Typography>
              <ArrowDropDownSharp sx={{ paddingTop: '5px' }} />
            </Stack>
            <Box
              sx={{
                display: 'flex',
                maxWidth: ChatMessageWidth,
                width: '100%',
                justifyContent: 'space-between',
                flexDirection: !smDown ? 'row' : 'column',
                paddingTop: '5px',
                px: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: !smDown ? 'center' : 'flex-start',
                  width: !smDown ? '87%' : '100%',
                }}
              >
                <Box
                  sx={{
                    marginLeft: '4px',
                    marginTop: !smDown ? '4px' : '8px',
                  }}
                >
                  <Box
                    sx={{
                      color:
                        settings.theme == 'DARK'
                          ? senderType === 'user'
                            ? '#3C4A59'
                            : 'white'
                          : '#3C4A59',
                      px: 1,
                    }}
                  >
                    <Box sx={{ mt: 0, ml: 1 }}>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '0.875rem',
                            sm: '0.875rem',
                            md: '0.875rem',
                            lg: '1rem',
                            xl: '1.125rem'
                          },
                          color: '#3C3C3C'
                        }}
                      >

                        <Stack spacing={1} direction="row">
                          <Avatar
                            variant="square"
                            className="user-avatar"
                            src={senderAvatar}
                            sx={{
                              height: 30,
                              width: 30,
                              borderRadius: '5px',
                            }}
                          />
                          <Box style={{ marginLeft: '10px' }}>
                            <TypingMarkdown
                              content={chatTranslation.question}
                              speed={20}
                              threadId={thread?.external_chat_id}
                              showTyping={false}
                              msgId={msgId}
                              onTypingComplete={() => { }}
                            />
                          </Box>
                        </Stack>

                        <Stack spacing={1} direction="row" style={{ marginTop: '10px' }}>
                          <Avatar
                            variant="square"
                            sx={{
                              height: 30,
                              width: 30,
                              borderRadius: '5px',
                              backgroundColor: 'transparent',
                            }}
                          >
                            <img
                              src={selectAvatarSrc()}
                              style={{ height: '30px', width: '30px' }}
                            />
                          </Avatar>
                          <Box style={{ marginLeft: '10px' }}>
                            <TypingMarkdown
                              content={chatTranslation.answer}
                              speed={20}
                              showTyping={false}
                              msgId={msgId}
                              threadId={''}
                              onTypingComplete={() => { }}
                            />
                          </Box>
                        </Stack>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </RTL>
      )}
      <ThumbsUpDownDialog
        open={thumbsUpDownDialogOpen}
        setOpen={setThumbsUpDownDialogOpen}
      />
    </div>
  );
};

ChatMessage.propTypes = {
  loader: PropTypes.any,
  body: PropTypes.any.isRequired,
  senderAvatar: PropTypes.string.isRequired,
  senderType: PropTypes.oneOf(['dewa', 'user']),
  threadKey: PropTypes.any,
  thread: PropTypes.any,
  index: PropTypes.any,
  showIcons: PropTypes.bool,
  msgId: PropTypes.string,
  externalConvId: PropTypes.string,
};

export default React.memo(ChatMessage);
