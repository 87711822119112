import { PropsWithChildren, useState } from 'react';
import { DashboardLayoutContainer, DashboardLayoutContent, DashboardLayoutRoot, DashboardLayoutWrapper } from 'src/admin/components/adminLayout/AdminLayout';
import DashboardNavbar from 'src/admin/components/adminLayout/AdminNavbar';
import ChatSidebar from 'src/components/dashboard/chat/ChatSidebar';
import RTL from '../../components/RTL';

const ChatLayout = ({ children }: PropsWithChildren<any>) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

  return (
    <DashboardLayoutRoot className="dashboard">
      <DashboardNavbar
        showBackToChat={false}
        sx={{
          background: '#fff !important'
        }}
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <RTL direction="ltr">
        <ChatSidebar
          showSections={false}
          showBackToChatButton={false}
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <DashboardLayoutWrapper className="bg-light-gray">
          <DashboardLayoutContainer className="bg-light-gray">
            <DashboardLayoutContent
              id="adminDashboard"
              className="bg-light-gray"
            >
              {children}
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </RTL>
    </DashboardLayoutRoot>
  );
};

export default ChatLayout;
