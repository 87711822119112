/*eslint-disable*/
import { useCallback, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TableContainer,
  CircularProgress,
  Dialog,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from '@material-ui/core';
import PencilAltIcon from '../../../../icons/PencilAlt';
import SearchIcon from '../../../../icons/Search';
import getInitials from '../../../../utils/getInitials';
import Scrollbar from '../../../../components/Scrollbar';
import { debounce, isEqual } from 'lodash';
import Label from 'src/components/Label';
import PreviewIcon from '@material-ui/icons/Preview';
import { useTheme } from '@material-ui/core/styles';
import { userChatSessions } from 'src/admin/api/user';
import {
  getChatsBySessionId,
  getConversationBySessionAndChatId,
} from 'src/api/chat';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  TableCellStyle,
  TableHeaderCellStyle,
} from '../content/DocumentListTable';
import { ChatMessage } from 'src/components/dashboard/chat';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import toast from 'react-hot-toast';
import cross from '../../../../icons/cancle.png';

interface UsersListTableProps {
  users: any;
  loading: boolean;
  getUsers: any;
  tableParams: { page: number; limit: number; query: string };
  setTableParams: React.Dispatch<
    React.SetStateAction<{ page: number; limit: number; query: string }>
  >;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };

const HierarchialListItem: FC<any> = ({
  id,
  option,
  setSelectedSessionId,
  selectedSessionId,
  setSelectedChatId,
  selectedChatId,
  loadConversationsBySessionAndChatId,
  setConversations,
  sessionStartDate,
  sessionEndDate,
  sessionId,
  setSessionId,
  setSelectedSessionStartDate,
  setSelectedSessionEndDate,
}) => {
  const [expand, setExpanded] = useState<boolean>(false);
  const [children, setChildren] = useState<any>(null);
  const theme = useTheme();

  const loadChatsBySessionId = async (sid: string) => {
    setSessionId(option.id);
    setSelectedSessionEndDate(new Date(sessionEndDate).toLocaleTimeString());
    setSelectedSessionStartDate(new Date(sessionStartDate).toLocaleTimeString());
    setSelectedSessionId(sid);
    setSelectedChatId(null);
    setConversations(null);

    const chats = await getChatsBySessionId(+sid)
      .then((resp) => resp?.data)
      .catch((err) => {
        console.log(err);
      });

    setChildren(
      chats.map((data) => ({
        id: data.external_chat_id,
        parentId: id,
        label: data.title,
      }))
    );
  };

  return (
    <Box
      className='question-list'
      sx={{
        p: 0.5,
        cursor: 'pointer',
        background:
          option.parentId === selectedSessionId && option.id === selectedChatId
            ? theme.palette.primary.main
            : 'white',
      }}
    >
      <Stack
        direction="row"
        component="div"
        // sx={{
        //   '&:hover': option.id !== selectedSessionId &&
        //     option.id !== selectedChatId && {
        //       background: '#F3f3f3',
        //     },
        // }}
        onClick={async (e) => {
          e.stopPropagation();
          setExpanded(true);
          if (!id.startsWith('child')) {
            loadChatsBySessionId(id);
          } else {
            await loadConversationsBySessionAndChatId(
              option.parentId,
              option.id,
              sessionId,
              sessionStartDate,
              sessionEndDate,
            );
          }
        }}
      >
        {!expand ? (
          <>
            {id.startsWith('child') ? (
              <ChatBubbleOutlineIcon
                style={{
                  color:
                    option.parentId === selectedSessionId &&
                      option.id === selectedChatId
                      ? 'white'
                      : theme.palette.primary.main,
                }}
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.125rem',
                    lg: '1.375rem',
                    xl: '1.375rem',
                  },
                  mr: 1,
                }}
              />
            ) : (
              <AddIcon sx={{ mr: 1 }} className="square-icon" />
            )}
          </>
        ) : (
          <>
            {id.startsWith('child') ? (
              <ChatBubbleOutlineIcon
                style={{
                  color:
                    option.parentId === selectedSessionId &&
                      option.id === selectedChatId
                      ? 'white'
                      : theme.palette.primary.main,
                }}
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.125rem',
                    lg: '1.375rem',
                    xl: '1.375rem',
                  },
                  mr: 1,
                }}
              />
            ) : (
              <RemoveIcon
                className="square-icon" 
                sx={{ mr: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(false);
                }}
              />
            )}
          </>
        )}
        <Typography
          sx={{
            color:
              option.parentId === selectedSessionId &&
                option.id === selectedChatId
                ? 'white'
                : 'black',
            fontSize: '0.9rem',
            fontWeight:
              !id.startsWith('child') && option.is_active ? '900' : 'normal',
          }}
        >
          {option.label}
        </Typography>
      </Stack>
      <Box sx={{ ml: '10px' }}>
        {expand && children && (
          <>
            {children.map((child) => (
              <HierarchialListItem
                loadConversationsBySessionAndChatId={
                  loadConversationsBySessionAndChatId
                }
                setConversations={setConversations}
                setSelectedSessionId={setSelectedSessionId}
                selectedSessionId={selectedSessionId}
                setSelectedChatId={setSelectedChatId}
                selectedChatId={selectedChatId}
                onClick={(e) => { }}
                onMouseOver={() => { }}
                id={`child-${child.id}`}
                sessionStartDate={sessionStartDate}
                sessionEndDate={sessionEndDate}
                setSelectedSessionStartDate={setSelectedSessionStartDate}
                setSelectedSessionEndDate={setSelectedSessionEndDate}
                sessionId={sessionId}
                setSessionId={setSessionId}
                option={child}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

const UsersListTable: FC<UsersListTableProps> = (props) => {
  const { users, loading, getUsers, tableParams, setTableParams, ...other } =
    props;
    const theme = useTheme();
  const users1 = [
    {
      id: 1,
      first_name: 'Jahanzeb',
      last_name: 'Warraich',
      email: 'jahanzeb.warraich@northbaysolutions.net',
      isActive: true,
    },
    {
      id: 2,
      first_name: 'Waqar',
      last_name: 'Shakeel',
      email: 'waqar.shakeel@northbaysolutions.net',
      isActive: false,
    },
  ];
  const { page, limit, query } = tableParams;

  const [openChatHistory, setOpenChatHistory] = useState<boolean>(false);
  const [userSessions, setUserSessions] = useState<any>(null);
  const [conversations, setConversations] = useState<any>(null);
  const [selectedSessionId, setSelectedSessionId] = useState<number>(null);
  const [selectedChatId, setSelectedChatId] = useState<string>(null);
  const [selectedUserName, setSelectedUserName] = useState<string>('');
  const [selectedSessionEndDate, setSelectedSessionEndDate] = useState<string>(null);
  const [selectedSessionStartDate, setSelectedSessionStartDate] = useState<string>(null);

  const [sessionId, setSessionId] = useState<string>(null);
  const [isLoadingConversations, setIsLoadingConversations] =
    useState<boolean>(false);

  const debouncedGetUsers = useCallback(debounce(getUsers, 500), []); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    if (!isEqual(query, newValue)) {
      // debouncedGetUsers(0, limit, newValue);
      setTableParams((prevParams) => ({
        ...prevParams,
        page: 0,
        query: newValue,
      }));
    }
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    getUsers(newPage, limit, query);
    setTableParams((prevParams) => ({ ...prevParams, page: newPage }));
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const updatedLimit = parseInt(event.target.value, 10);
    getUsers(page, updatedLimit, query);
    setTableParams((prevParams) => ({ ...prevParams, limit: updatedLimit }));
  };

  const getUserStatus = (userStatus: boolean): JSX.Element => {
    const colorCode = userStatus ? 'success' : 'error';
    return <Label color={colorCode}>{userStatus ? 'Yes' : 'No'}</Label>;
  };

  const loadUserSessions = async (userId: string) => {
    // const sessions = [
    //   {
    //     id: 1,
    //     session_id: "vh7406yLQ1V0bnt4128FSx",
    //     external_user_id: "i330ogEq8IxCQOosuTTdmA",
    //     start_datetime: "2021-01-10 14:54:36.638514",
    //     end_datetime: "2021-01-10 14:54:36.638514"
    //   },
    //   {
    //     id: 2,
    //     session_id: "vh7406yLQ1V0bnt4128FSx1",
    //     external_user_id: "i330ogEq8IxCQOosuTTdmA",
    //     start_datetime: "2021-01-10 14:54:36.638514",
    //     end_datetime: null
    //   },
    // ];
    const sessions = await userChatSessions(userId).then((resp) => resp.data);
    setUserSessions(sessions);
    if (sessions?.length !== 0) {
      setOpenChatHistory(true);
    } else {
      toast.error('No session found');
    }
  };

  const loadConversationsBySessionAndChatId = async (
    sessionId: number,
    chatId: string,
    sessionStringId,
    sessionStartDate: string,
    sessionEndDate: string,
  ) => {
    setSelectedSessionId(sessionId);
    setSelectedChatId(chatId);
    setIsLoadingConversations(true);
    setSessionId(sessionStringId);
    setSelectedSessionEndDate(new Date(sessionEndDate).toLocaleTimeString());
    setSelectedSessionStartDate(new Date(sessionStartDate).toLocaleTimeString());
    setConversations(null);
    const conversations = await getConversationBySessionAndChatId(
      sessionId,
      chatId
    )
      .then((resp) => resp?.data)
      .catch((err) => {
        setIsLoadingConversations(false);
      });

    setConversations(conversations);
    setIsLoadingConversations(false);
  };
  const getStyles = () => {
    if (
      isLoadingConversations ||
      (selectedSessionId && (!conversations || !conversations.length))
    ) {
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
    }

    return {};
  };

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            width: '100%',
          }}
        >
          <Typography
            color="textPrimary"
            variant="h5"
            style={{ color: "#164396", fontSize: '24px', fontFamily: 'Lato', fontWeight: '700', marginBottom: '20px' }}
          >
            Users Details
          </Typography>
          <Box className="search-user">
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search"
              value={query}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 2 }} className="pd-box-top0">
        <TableContainer>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Scrollbar>
                <Box sx={{ minWidth: 700 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={TableHeaderCellStyle}>Name</TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Username
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Designation
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Department
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Division
                        </TableCell>

                        {/* <TableCell sx={TableHeaderCellStyle} align="center">Active Session</TableCell> */}
                        <TableCell sx={TableHeaderCellStyle} align="center">
                          Session History
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow hover key={user.id}>
                          <TableCell sx={TableCellStyle}>
                            {user.first_name + ' ' + user.last_name}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {user.email}
                          </TableCell>

                          <TableCell sx={TableCellStyle}>
                            {user.designation_name}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {user.department_name}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {user.division_name}
                          </TableCell>

                          {/* <TableCell sx={TableCellStyle} align="center">{getUserStatus(user.is_session_active)}</TableCell> */}
                          <TableCell align="center" sx={TableCellStyle}>
                            <IconButton
                              sx={{ padding: '0px' }}
                              onClick={() => {
                                setSelectedUserName(
                                  user.first_name + ' ' + user.last_name
                                );
                                loadUserSessions(user.external_user_id);
                              }}
                            >
                              <PreviewIcon
                                sx={{
                                  color: user.is_session_active
                                    ? `${theme.palette.primary.main} !important`
                                    : 'grey !important',
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </>
          )}
        </TableContainer>
        <TablePagination
          component="div"
          count={users?.count || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={false}
        />
      </Box>
      <Dialog
        open={openChatHistory}
        BackdropProps={{ style: { opacity: 0.5 } }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={{ pb: 0, px: 0 }}>
          <Box sx={{ px: 2 }}>
            <span>
              <Typography style={{ display: 'inline-block', color: "#164396", fontWeight: 700 }} component="h1">
                Sessions:
              </Typography>
              <Typography
                style={{ display: 'inline-block', fontWeight: 'bold', color: '#515151' }}
                component="h1"
              >
                &nbsp;{selectedUserName}
              </Typography>
            </span>
            {selectedSessionId && <span style={{ float: 'right', fontSize: '16px' }}>
              <Typography component="b" style={{ fontSize: '16px' }}>
                <span style={{ color: '#164396' }}>Session Id:</span> <span style={{ color: '#515151' }}>{sessionId}</span>
              </Typography>
              <br />
              <span>
                <Typography component="span" style={{ fontSize: '16px' }}>
                  <span style={{ color: '#164396' }}>Start Time:</span> <span style={{ color: '#515151' }}>{selectedSessionStartDate}</span>
                </Typography>
                <Typography component="span" style={{ marginLeft: '0.5rem', fontSize: '16px' }}>
                  <span style={{ color: '#164396' }}>End Time:</span> <span style={{ color: '#515151' }}>{selectedSessionEndDate}</span>
                </Typography>
              </span>
            </span>}
          </Box>
            <Divider sx={{ width: '100%', borderWidth: '1px', mt: '10px' }} />
        </DialogTitle>
        <DialogContent sx={{ pt: 0, pb: 0 }}>
          <Box width="100%" sx={{ display: 'flex', height: '58vh' }}>
            <Box
              width="300px"
              sx={{
                borderRight: '1px solid #ccc',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <List
                sx={{
                  width: '100%',
                  background: '#FDFDFD',
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                {userSessions &&
                  userSessions.map((session) => (
                    <HierarchialListItem
                      loadConversationsBySessionAndChatId={
                        loadConversationsBySessionAndChatId
                      }
                      setConversations={setConversations}
                      setSelectedSessionId={setSelectedSessionId}
                      selectedSessionId={selectedSessionId}
                      selectedChatId={selectedChatId}
                      setSelectedChatId={setSelectedChatId}
                      sessionStartDate={session.start_datetime}
                      sessionEndDate={session.end_datetime}
                      setSelectedSessionStartDate={setSelectedSessionStartDate}
                      setSelectedSessionEndDate={setSelectedSessionEndDate}
                      id={session.id.toString()}
                      sessionId={session.session_id}
                      setSessionId={setSessionId}
                      option={{
                        id: session.session_id,
                        label: new Date(session.start_datetime).toLocaleString('en-US', dateFormatOptions),
                        is_active: session.is_active,
                      }}
                    />
                  ))}
              </List>
            </Box>
            <Box
              sx={{
                width: 'calc(100% - 300px)',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                  ...getStyles()
                }}
                className="user-chat"
              >
                {conversations &&
                  conversations.map(({ question, answer, id }: any) => (
                    <>
                      <ChatMessage
                        body={question}
                        loader={null}
                        showIcons={true}
                        key={`questionMessageId${id}`}
                        thread={''}
                        threadKey={''}
                        senderAvatar={
                          '/static/mock-images/avatars/avatar-jane_rotanson.png'
                        }
                        senderType={'user'}
                        msgLoading={false}
                      />

                      <ChatMessage
                        body={answer}
                        source_documents={null}
                        loader={null}
                        msgLoading={false}
                        showIcons={false}
                        loadAtOnce
                        key={`answerMessageId${id}`}
                        msgId={id}
                        thread={''}
                        threadKey={''}
                        senderAvatar={
                          '/static/mock-images/avatars/avatar-jane_rotanson.png'
                        }
                        senderType={'dewa'}
                      />
                    </>
                  ))}
                {selectedSessionId &&
                  selectedChatId &&
                  !isLoadingConversations &&
                  (!conversations || !conversations.length) && (
                    <Typography variant="h4">
                      No conversation history found.
                    </Typography>
                  )}
                {isLoadingConversations && (
                  <CircularProgress
                    style={{ color: theme.palette.primary.main, width: '4rem', height: '4rem' }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ pr: 3 }} style={{ borderTop: '1px solid #BFBFBF' }}>
          <Button
            className="btn-document btnPrimary"
            onClick={() => {
              setSelectedChatId(null);
              setConversations(null);
              setOpenChatHistory(false);
            }}
            style={{ width: '125px'}}
          >
            <img src={cross} style={{ marginRight: '5px' }} />
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card >
  );
};

UsersListTable.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getUsers: PropTypes.func.isRequired,
  tableParams: PropTypes.any.isRequired,
  setTableParams: PropTypes.func.isRequired,
};

export default UsersListTable;
