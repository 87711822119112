/*eslint-disable*/
import { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  CircularProgress,
} from '@material-ui/core';
import TrashIcon from 'src/icons/TrashIcon.svg';
import NEW_FILE_ICON from 'src/icons/new-file-icon.png';
import Scrollbar from 'src/components/Scrollbar';
import ContentDeleteDialog from './ContentDeleteDialog';
import { deleteContent } from 'src/admin/api/content';
import { DEPARTMENTS, SECURITY_CLEARANCE } from 'src/components/navigation/MainNav';
import { capitalize } from 'lodash';
import { useSelector } from 'src/store';

interface DocumentListTableProps {
  contents: any;
  loading: boolean;
  getContents: any;
}

declare module 'react' {
  interface ImgHTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    align?: string;
  }
}

export const TableCellStyle = {
  padding: '8px 16px',
  fontSize: '0.75rem'
};

export const TableHeaderCellStyle = {
  padding: '8px 16px',
  fontWeight: 'bold',
};

const DocumentListTable: FC<DocumentListTableProps> = (props) => {
  const {
    contents,
    loading,
    getContents,
    ...other
  } = props;


  const [open, setOpen] = useState(false);
  const [currentContent, setContent] = useState(null);
  const { byId = {} } = useSelector(state => state.metadata);

  return (
    <Card {...other}>
      <h2 className='minus-pb10' style={{ paddingLeft: '30px' }}>Documents </h2>
      <Box sx={{ pb: 2 }} className='pd-box-top0'>
        <TableContainer>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Scrollbar>
                <Box sx={{ minWidth: 700 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={TableHeaderCellStyle} width="30%">Document Name</TableCell>
                        <TableCell sx={TableHeaderCellStyle} align='left' width="15%">Status</TableCell>
                        <TableCell sx={TableHeaderCellStyle} align='left' width="20%">Date</TableCell>
                        <TableCell sx={TableHeaderCellStyle} align='left' width="15%">Deparment</TableCell>
                        <TableCell sx={TableHeaderCellStyle} align='left' width="12%">Security Level</TableCell>
                        <TableCell sx={TableHeaderCellStyle} align='center' width="8%">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contents.map((content) => (
                        <TableRow key={content.external_uid}>
                          <TableCell title={content.source_name} sx={TableCellStyle} className='document-content' align="left">
                            <div className='icon-container'>
                              <img align="true" src={NEW_FILE_ICON} />
                              <p>{content.source_name.split('.').pop()}</p>
                            </div>
                            <span style={{
                              maxWidth: '85%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}>{content.source_name}</span>
                          </TableCell>
                          <TableCell sx={TableCellStyle} align="left">{capitalize(content.ingestion_status)}</TableCell>
                          <TableCell sx={TableCellStyle} align="left">{new Date(content.created_at).toLocaleString()}</TableCell>
                          <TableCell sx={TableCellStyle} align="left">{byId[content.department_id]}</TableCell>
                          <TableCell sx={TableCellStyle} align="left">{SECURITY_CLEARANCE[content.security_level]}</TableCell>
                          <TableCell sx={TableCellStyle} align="center">
                            <IconButton
                              sx={{
                                padding: 0
                              }}
                              onClick={() => {
                                setOpen(true);
                                setContent(content['external_uid']);
                              }}
                            >
                              <img src={TrashIcon} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}

                      {(open && currentContent) && (
                        <ContentDeleteDialog
                          sourceType='document'
                          open={open}
                          onOk={async () => {
                            await deleteContent(currentContent);
                            getContents("document");
                            setOpen(false);
                          }}
                          onCancel={() => setOpen(false)}
                        />
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </>
          )}
        </TableContainer>
      </Box>
    </Card>
  );
};

DocumentListTable.propTypes = {
  contents: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getContents: PropTypes.func.isRequired
};

export default DocumentListTable;
