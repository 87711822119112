import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setModelType } from 'src/slices/chat';
import { RootState, useDispatch } from 'src/store';
import { RESPONSE_MODEL_TYPES } from 'src/constants';

export const ModelTypeSelect = () => {
  const dispatch = useDispatch();
  const { selectedModelType } = useSelector((state: RootState) => state.chat);

  useEffect(() => {
    if (!selectedModelType) {
      dispatch(setModelType(RESPONSE_MODEL_TYPES[0].value));
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, width: '275px', height: '56px', marginTop: '8px' }}>
      <FormControl sx={{ width: '99%', height: '56px' }}>
        <InputLabel id="model-type-label">Select Model *</InputLabel>
        <Select
          size="small"
          sx={{
            fontSize: '0.875rem',
            height: '56px',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          value={selectedModelType}
          labelId="model-type-label"
          id="model-type-label-select"
          label="Select Model *"
          onChange={(e: any) => {
            dispatch(setModelType(e.target.value));
          }}
        >
          {RESPONSE_MODEL_TYPES.map((res) => (
            <MenuItem
              sx={{ '&:hover': { backgroundColor: '#164396' } }}
              value={res.value}
              key={res.value}
            >
              {res.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
