/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { saveDepartmentsList } from 'src/store/localStorage';
import i18n from 'src/i18n';
import { getDepartments } from 'src/admin/api/metadata';

type sender = 'user' | 'dewa' | 'contact';

interface ApprovalMessages {
  text: string;
  sender: sender;
}

interface Metadata {
  retainContext: boolean;
  chatHistory: string;
  departments: any;
  byId: { [key: string]: string };
  userApproval?: boolean;
  isOTPdone: boolean;
  optFlowState: any;
  originalQuestion: string;
  approvalMessages: ApprovalMessages[];
}

const initialState: Metadata = {
  retainContext: false,
  chatHistory: '',
  departments: null,
  byId: null,
  userApproval: false,
  isOTPdone: false,
  optFlowState: null,
  originalQuestion: '',
  approvalMessages: [
    {
      text: 'This conversation will be recorded, do you agree?',
      sender: 'dewa',
    },
  ],
};

const slice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setDepartments(state: Metadata, action: PayloadAction<any>): void {
      state.departments = action.payload;
      const departmentByIds = {};
      state.departments?.forEach((d) => {
        departmentByIds[d.id] = d.name;
      });

      state.byId = departmentByIds;
    },
    clearDepartments(state: Metadata, action: PayloadAction<any>): void {
      state.departments = action.payload;
      state.byId = null;
    },
    setUserApproval(state: Metadata, action: PayloadAction<any>): void {
      state.userApproval = action.payload;
    },
    setOTPdone(state: Metadata, action: PayloadAction<any>): void {
      state.isOTPdone = action.payload;
    },
    setotpFlowState(state: Metadata, action: PayloadAction<any>): void {
      state.optFlowState = action.payload;
    },
    setOriginalQuestion(state: Metadata, action: PayloadAction<any>): void {
      state.originalQuestion = action.payload;
    },
    setApprovalMessages(state: Metadata, action: PayloadAction<any>): void {
      state.approvalMessages = [...state.approvalMessages, action.payload];
    },
    resetApprovalMessages(state: Metadata, action: PayloadAction<any>): void {
      state.approvalMessages = [action.payload];
    },
    setRetainContext(state: Metadata, action: PayloadAction<any>): void {
      state.retainContext = action.payload;
    },
    setChatHistory(state: Metadata, action: PayloadAction<any>): void {
      state.chatHistory = `${state.chatHistory}${action.payload}${' \n\n'}`;
    },
    clearChatHistory(state: Metadata, action: PayloadAction<any>): void {
      state.chatHistory = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setDepartments =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setDepartments(data));
    saveDepartmentsList(data);
  };

export const getDepartmentsList =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const response = await getDepartments();
      const departments = response?.data;
      dispatch(setDepartments(departments));
    } catch (error) {
      throw new Error(i18n.t('departmentsFetchError'));
    }
  };

export const clearDepartments =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.clearDepartments(null));
    saveDepartmentsList(null);
  };

export const setUserApproval =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setUserApproval(data));
  };

export const setOTPdone =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setOTPdone(data));
  };

export const setotpFlowState =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setotpFlowState(data));
  };

export const setOriginalQuestion =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setOriginalQuestion(data));
  };

export const setApprovalMessages =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setApprovalMessages(data));
  };

export const resetApprovalMessages =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.resetApprovalMessages(data));
  };

export const setRetainContext =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setRetainContext(data));
  };

export const setChatHistory =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setChatHistory(data));
  };

export const clearChatHistory =
  (data): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.clearChatHistory(data));
  };

export default slice;
