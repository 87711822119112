import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const MenuClose = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51" fill="none">
    <circle cx="25" cy="25.5" r="25" fill="white" />
    <path
      d="M32.2338 16.2254L14.949 33.5102C14.3523 34.1068 14.3523 35.0742 14.949 35.6708C15.5456 36.2675 16.5129 36.2675 17.1096 35.6708L34.3944 18.386C34.991 17.7894 34.991 16.822 34.3944 16.2254C33.7978 15.6287 32.8304 15.6287 32.2338 16.2254Z"
      fill="#034EA1"
    />
    <path
      d="M34.8666 33.2602L17.5818 15.9754C16.9851 15.3787 16.0178 15.3787 15.4212 15.9754C14.8245 16.572 14.8245 17.5394 15.4212 18.136L32.706 35.4208C33.3026 36.0175 34.27 36.0175 34.8666 35.4208C35.4632 34.8242 35.4632 33.8568 34.8666 33.2602Z"
      fill="#034EA1"
    />
  </svg>,
  'TIIMenuClose'
);

export default MenuClose;
