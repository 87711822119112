/*eslint-disable*/
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import type { FC } from 'react';
import Button from '@mui/material/Button';
import { loginWithCreds } from 'src/api/auth';
import { isEmpty } from 'lodash';
import { useDispatch } from 'src/store';
import { extractUserSessionFromPayload } from 'src/utils';
import { setUnauthenticatedUserSession, setUserAuthenticationStatus } from 'src/slices/auth';
import { getUserProfile } from 'src/slices/user';
import { getDepartmentsList } from 'src/slices/metadata';
import CompanyLogo from 'src/icons/white-logo.png';
import ChatbotIcon from 'src/icons/chat-live.png';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Box,
  Toolbar,
  Typography,
  List,
  Link,
  ListItemText,
  ListItem,
} from '@material-ui/core';

const LandingHomePage: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const pages = ['Company', 'Service', 'Investor', 'Governance', 'Sustainability'];
  	const { t, i18n: lang } = useTranslation();
	const handleGuestLogin = async () => {
		const email = "guest@northbaysolutions.net"
		const res = await loginWithCreds(email, "Password@123");
		if (!isEmpty(res)) {
			await dispatch(setUnauthenticatedUserSession(extractUserSessionFromPayload(res)));
			// TODO:
			await dispatch(getUserProfile(email));
			await dispatch(setUserAuthenticationStatus(true));
			await dispatch(getDepartmentsList());
		}
	}

	return (
		<>
			<Box className='landing-page'>
				<Box
					className="landing-wrapper"
					sx={{
						background: `url(/images/header-banner.jpg)`
					}}
				>
					<Container maxWidth="lg" className="landing-menu-container">
						<Box>
							<Toolbar disableGutters>
								<RouterLink to="/">
									<img alt="White Logo" src={CompanyLogo} style={{ width: 111, marginRight:'30px' }} />
								</RouterLink>
								<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
									<List className="landing-menu-items">
									{pages.map((page) => (
										<ListItem>
											<ListItemText
												key={page}
												sx={{ my: 2, color: 'white', display: 'block' }}
											>
												<Link component={RouterLink} to='#' className="landing-menu-item">{page}</Link>
											</ListItemText>
										</ListItem>
									))}
									</List>
								</Box>

								<Box sx={{ flexGrow: 0 }}>
									<Button className="transparent-button" onClick={() => { navigate('/auth/login') }}>Login</Button>
								</Box>
							</Toolbar>
						</Box>
					</Container>
					<Container>
						<Box>
							<Typography variant="h3" sx={{ fontWeight: '500', color:'#fff', mt:3 }}>
								XL Axiata Generative AI
							</Typography>
						</Box>
					</Container>
				</Box>
				<Box
					className="landing-content-wrapper"
					sx={{
						background: `url(/images/content-landing-bg.jpg)`
					}}
				>
					<Container maxWidth="lg" className="landing-content-container">
						<Typography className="text-center">
							XL Axiata, a leading telecommunications company in Indonesia, is utilizing Generative AI to significantly improve its operations and customer experience.
							By implementing AI-powered chatbots, they provide efficient and personalized customer service, reducing response times and enhancing satisfaction.
							Generative AI also aids in creating targeted marketing content and optimizing social media engagement. 
						</Typography>
					</Container>
				</Box>
				<Box
					className="landing-footer"
				>
					<Container maxWidth="lg" className="landing-footer-container">
						<Box className="footer-links">
							<Link
								target="_blank"
								color="primary"
								component={RouterLink}
								sx={{ mt: 0, mr: '5px' }}
								to=""
								onClick={(ev: any) => ev.preventDefault()}
								variant="body2"
								>
									{t('loginForm.policy.termsOfUse')}
							</Link>
							<Typography variant="body2" sx={{ color: 'text.primary' }}>
								{' | '}
							</Typography>
							<Link
								target="_blank"
								color="primary"
								onClick={(ev: any) => ev.preventDefault()}
								component={RouterLink}
								sx={{ mt: 0, ml: '5px' }}
								to=""
								variant="body2"
								>
								{t('loginForm.policy.privacyPolicy')}
								</Link>
						</Box>
						<Box>
							<Typography className="landing-copyrights">
								© 2023 PT XL Axiata Tbk.
							</Typography>
						</Box>
					</Container>
				</Box>
				<Box className="chatbot-wrapper">
					<img alt="Chatbot" src={ChatbotIcon} className="chatbot-icon" onClick={handleGuestLogin} />
				</Box>
			</Box>
		</>
	);
};

export default LandingHomePage;
