/*eslint-disable*/
import { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { CustomerListTable } from '../../../components/dashboard/customer';
import useMounted from '../../../../hooks/useMounted';
import ChevronRightIcon from '../../../../icons/ChevronRight';
import PlusIcon from '../../../../icons/Plus';
import useSettings from '../../../../hooks/useSettings';
import gtm from '../../../../lib/gtm';
import { listUser } from 'src/admin/api/user';
import UsersListTable from 'src/admin/components/dashboard/users/UsersListTable';
import { POWERBI_DASHBOARD_URL } from 'src/config';

const DashboardAnalytics: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [users, setUsers] = useState<any[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  // const [addCustomer, setAddCustomer] = useState(false);
  const [tableParams, setTableParams] = useState<{
    page: number;
    limit: number;
    query: string;
  }>({
    page: 0,
    limit: 5,
    query: '',
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getUsers = useCallback(
    async (pageNum = 0, limit = 10, query = '') => {
      try {
        setLoadingUsers(true);
        const queryString = `limit=${limit}`;
        const res = await listUser(queryString);
        if (mounted.current) {
          setUsers(res?.data);
          setLoadingUsers(false);
        }
      } catch (err) {
        console.error(err);
        setLoadingUsers(false);
      }
    },
    [mounted]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Analytics | XL Axiata Chatbot</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 1,
        }}
      >
        {/* <Container maxWidth={settings.compact ? 'xl' : false}> */}
        {/* <Grid container justifyContent="space-between" spacing={3}> */}
        {/* <Grid item> */}
        <iframe title="XL-POC" style={{ height: '86vh', width: '80vw' }}
          src={POWERBI_DASHBOARD_URL}>
        </iframe>
        {/* <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  className="navLinkColorPrimary"
                  component={RouterLink}
                  to="#"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  className="navLinkColorPrimary"
                  component={RouterLink}
                  to="#"
                  variant="subtitle2"
                >
                  Management
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Users
                </Typography>
              </Breadcrumbs> */}
        {/* </Grid> */}
        {/* <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => setAddCustomer(true)}
                >
                  Add Customer
                </Button>
              </Box>
            </Grid> */}
        {/* </Grid> */}
        {/* <Box sx={{ mt: 3 }}>
            <UsersListTable
              users={users}
              loading={loadingUsers}
              getUsers={getUsers}
              tableParams={tableParams}
              setTableParams={setTableParams}
            />
          </Box> */}
        {/* </Container> */}
      </Box>
    </>
  );
};

export default DashboardAnalytics;
